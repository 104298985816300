export default {


    // url: 'http://127.0.0.1:3000/',
    url: 'https://covermygadgets.com/gadgets/public/',
    // url2: 'https://covermygadgets.com/gadgets/public/',
    // crm: 'https://repair-network.co.uk/repair_network_crm/cmgapi/',
    // crm: 'http://192.168.1.13/rncrm/cmgapi/',
    crm : 'https://smartapps-yarabltd.co.uk/rncrm/cmgapi/',
    // crm: 'https://335-crm.repair-network.co.uk/cmgapi/',
    // crm: 'https://smartapps-yarabltd.co.uk/rncrm/cmgapi/',
    // siteurl : 'https://smart-cover.co.uk',
    // siteurl : 'http://localhost:3000',
    siteurl : 'https://demo.covermygadgets.com/',
    env : 'prod'//prod,dev
}