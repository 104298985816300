import React, { Component } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Config from "../layout/Config";
import moment from "moment";
import Sidebanner from "../layout/Sidebanner";
import Printer, { print } from "react-pdf-print";
import MetaTags from 'react-meta-tags';

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gadget_list: [],
      price_list: [],
      makeOptionList: [],
      modelOptionList: [],
      rangeOptionList: [],
      removed_items: "",
      products: [],
      id: "",
      glength: "",
      loading: true,
      discount: "",
      details: "",
      data: [],
      cu_id: "",
      fname: "",
      currency_code: "",
      paymentDetails: [],
      orders: [],
      quote_id: "",
      order_id: "",
      amount: 0,
      reference_no: "",
      payment_status: [],
      status: "",
      monthly_price: "",
      yearly_price: " ",
      iptval_monthly: "",
      iptval_yearly: "",
      ipt_percentage: "",
      planprice:''
    };

    this.removeProduct = this.removeProduct.bind(this);
    this.amount = this.amount.bind(this);
    this.showContent = this.showContent.bind(this);
  }
  print() {
    window.print();
  }
  amount(event) {
    this.setState({
      amount: event.target.value
    });
  }
  showContent = () => {
    localStorage.removeItem("payment_status");
    this.setState({
      status: false
    });
  };
  removeProduct = (id, key) => {
    const headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "multipart/form-data");
    const url2 = Config.url + "api/destroy-gadget/" + id;
    fetch(url2, { method: "GET", headers })
      .then(function(response) {
        return response.json();
      })
      .then(jsonStr => {
        this.setState({
          removed_items: jsonStr
        });
      });

      console.log(this.state.removed_items);
    var test = localStorage.getItem("test")
      ? JSON.parse(localStorage.getItem("test"))
      : [];
    test.splice(key, 1);
    localStorage.setItem("test", JSON.stringify(test));
    localStorage.setItem("glength", JSON.stringify(test.length));
  };

  addPriceDetails = () => {
    alert("test");
    // if (this.state.amount == this.state.yearly_price) {
      localStorage.setItem("frequency", "Annualy");
      localStorage.setItem("amount", this.state.planprice);
    // }

    // if (this.state.amount == this.state.monthly_price) {
    //   localStorage.setItem("frequency", "monthly");
    // }
   
    localStorage.setItem("order_id", this.state.reference_no);

    // var apiUrl = Config.url + "api/price";
    // const data = {
    //   quote_id: localStorage.getItem("quote_id"),
    //   amount: localStorage.getItem("amount"),
    //   currency_code: localStorage.getItem("currency_code"),
    //   order_id: localStorage.getItem("order_id")
    // };

    const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    // myHeaders.append("Content-Type", "multipart/form-data");
    // const options = {
    //   method: "POST",
    //   body: JSON.stringify(data),
    //   myHeaders
    // };
    // fetch(apiUrl, options)
    //   .then(function(response) {
    //     return response.json();
    //   })
    //   .then(
    //     jsonStr => {
    //       this.setState({
    //         paymentDetails: jsonStr
    //       });
          const price = {
            price: localStorage.getItem("planprice"),
            frequency: localStorage.getItem("frequency")
          };
          const datas = {
            reference_id: localStorage.getItem("reference_no"),
            price_details: price
          };
          const crmstep2 = Config.crm + "processstep3";
          const options1 = {
            method: "POST",
            body: JSON.stringify(datas),
            myHeaders
          };
          fetch(crmstep2, options1)
            .then(function(response) {
              return response.json();
            })
            .then(
              jsonStr => {
                this.setState({
                  api_response: jsonStr
                });
                window.location = "/quotes/Payment";
              },
              error => {
                this.setState({ error });
              }
            );
      //   },
      //   error => {
      //     this.setState({ error });
      //   }
      // );

    // if (this.state.amount == "") {
    //   localStorage.setItem("amount", this.state.price_list["yearly_price"]);
    // } else {
    //   localStorage.setItem("amount", this.state.amount);
    // }
    localStorage.setItem("order_id", this.state.reference_no);
  };

  componentDidMount() {
    if (localStorage.getItem("glength") <= 0) {
      window.location = "/";
    }
    const headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "multipart/form-data");

    //price plan 
    var planprice = localStorage.getItem("planprice") ;
    
    const url =
      Config.url + "api/gadget-list/" + localStorage.getItem("quote_id");
    fetch(url, { method: "GET", headers })
      .then(function(response) {
        return response.json();
      })
      .then(jsonStr => {
        this.setState({
          gadget_list: jsonStr
        });
        const glength = this.state.gadget_list.length;
     console.log(jsonStr);
     var valueprice = 0 ;

     jsonStr.map(function(item, key) {
        valueprice = parseInt(valueprice) + parseInt(item.range_id) ;
       });
      //  console.log(this.state.gadget_list);
       if(valueprice <= 500){
        this.setState({ 
          planprice : 59.99
        });
      }else if(valueprice <= 1000){
        this.setState({ 
          planprice : 79.99
        });
      }else{
        this.setState({ 
          planprice : 99.99
        });
      }
      // console.log(this.state.planprice);
      localStorage.setItem("planprice", this.state.planprice);

      });
    //  var valueprice = 0 ;
    //  console.log(this.state.gadget_list);

      // this.state.gadget_list.map(function(item, key) {
      //   valueprice = parseInt(valueprice) + parseInt(item.range_id) ;
      //  });
      //  console.log(this.state.gadget_list);
      //  if(valueprice <= 500){
      //   this.setState({ 
      //     pricevalue : 59.99
      //   });
      // }else if(valueprice <= 1000){
      //   this.setState({ 
      //     pricevalue : 79.99
      //   });
      // }else{
      //   this.setState({ 
      //     pricevalue : 99.99
      //   });
      // }
      // console.log(this.state.pricevalue);
    // localStorage.setItem("planprice", this.state.gadget_list[0].planprice);


    // const url1 =
    //   Config.url + "api/price-list/" + localStorage.getItem("quote_id");
    // fetch(url1, { method: "GET", headers })
    //   .then(function(response) {
    //     return response.json();
    //   })
    //   .then(jsonStr => {
    //     this.setState({
    //       price_list: jsonStr[0]
    //     });
        ///// ipt settings///
        // const url10 = Config.url + "api/document";
        // fetch(url10, {
        //   method: "GET",
        //   headers
        // })
        //   .then(function(response) {
        //     return response.json();
        //   })
          // .then(jsonStr => {
          //   this.setState({ document: jsonStr });
          //   if (this.state.document == null) {
          //     this.state.document = null;
          //   }
          //   this.setState({
          //     ipt_percentage: jsonStr.ipt_percentage
          //   });
          //   var ipt_percentage_val = this.state.ipt_percentage;

            // var monthly_price = parseFloat(
            //   this.state.price_list["monthly_price"]
            // );
            // var monthly_price = monthly_price.toFixed(2);
            // var iptval_monthly = monthly_price * (ipt_percentage_val / 100);

            // var yearly_price = parseFloat(
            //   this.state.price_list["yearly_price"]
            // );
            // var yearly_price = yearly_price.toFixed(2);
            // var iptval_yearly = yearly_price * (ipt_percentage_val / 100);
            // this.setState({
            //   monthly_price: monthly_price,
            //   yearly_price: yearly_price,
            //   amount: yearly_price,
            //   iptval_monthly: iptval_monthly.toFixed(2),
            //   iptval_yearly: iptval_yearly.toFixed(2)
            // });
          // });
      // });
    const url3 = Config.url + "api/getProducts/";
    fetch(url3, { method: "GET", headers })
      .then(function(response) {
        return response.json();
      })
      .then(jsonStr => {
        this.setState({
          products: jsonStr
        });

      });

    const url4 =
      Config.url + "api/getPaymentStatus/" + localStorage.getItem("quote_id");
    fetch(url4, { method: "GET", headers })
      .then(function(response) {
        return response.json();
      })
      .then(jsonStr => {
        this.setState({
          payment_status: jsonStr
        });

        if (
          localStorage.getItem("payment_status") == "4" ||
          localStorage.getItem("payment_status") == "5"
        ) {
          this.setState({
            status: true
          });
          setTimeout(() => this.showContent(), 4000);
        }
      });

    this.setState({
      fname: localStorage.getItem("hacfname"),
      lname: localStorage.getItem("haclname"),
      dob: localStorage.getItem("hacdob"),
      post: localStorage.getItem("hacpost"),
      email: localStorage.getItem("hacemail"),
      tele: localStorage.getItem("hactele"),
      address: localStorage.getItem("hacaddress"),
      amount: localStorage.getItem("amount"),
      reference_no: localStorage.getItem("reference_no")
    });
  }
  render() {

    const styles = {
      payment_form: {
        display: "none"
      },
      payment_failure: {
        height: "200px"
      },
      plans: {
        textAlign: "center",
        fontWeight: "font-weight-bold"
      }
    };
    const { gadget_list } = this.state;

    return (
      <div>
        <Header />
        <MetaTags>
            <title>Gadgets Quote</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
        <section id="pg-produc">
          <div className="container">
          <div class="row">
            {/* <div class="col-md-4 col-lg-4 noprint">
            <Sidebanner />
            </div> */}
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="gen-your-quote">
                <div class="row noprint">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Generate your Quote</h3>
                    <p>
                      With just a few simple steps you will receive a
                      personalised quote.
                    </p>
                  </div>
                </div>
                <div class="pg-details-step noprint">
                  <div class="row justify-content-lg-around">
                    <div class="col-md-12 col-lg-2 pd-steps active">
                      <span class="prvstep">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </span>
                      Your Details <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="col-md-12 col-lg-2 pd-steps active">
                      <span class="prvstep">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </span>
                      Product Details <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="col-md-12 col-lg-2 pd-steps active mbactive">
                      <span class="activespan">3</span> Product Review
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="col-md-12 col-lg-2 pd-steps active">
                      <span >4</span> Payment Methods
                      <i class="fas fa-angle-right"></i>
                    </div>
                    
                    <div class="col-md-12 col-lg-2 pd-steps active">
                      <span >5</span> Policy Summary
                    </div>
                  </div>
                </div>
                
                {localStorage.getItem("payment_status") == "4" ||
                localStorage.getItem("payment_status") == "5" ? (
                  <div
                    class="sucess-sec failure text-center"
                    style={styles.payment_failure}
                  >
                    {this.state.position}
                    <div class="row">
                      <div class="col-md-12">
                        <i
                          class="fa fa-close dainger-ico mt-5"
                          aria-hidden="true"
                        ></i>
                        <h2 class="pt-3">payment declined</h2>
                        <h4> Please Try Again </h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div class="quote-summary-sec">
                  <div class="row">
                    <div class="col-md-9 mt-1">
                      <div class="qu-left">
                        
                        <form action="" method="">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Gadget</th>
                                <th scope="col">Model Number</th>
                                <th scope="col">Cover Level</th>                                
                                <th scope="col">New or Refurbished</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {gadget_list.map(
                                function(gadget_list, key) {
                                  return (
                                    <tr>
                                      <td> {gadget_list.title}</td>
                                      <td>
                                        {gadget_list.models}
                                      </td>
                                      <td>
                                        {parseFloat(gadget_list.range_id).toFixed(2)}
                                      </td>
                                      <td>
                                        {gadget_list.new_refurb == "1"
                                          ? "New"
                                          : "Refurbished"}
                                      </td>
                                      {localStorage.getItem("glength") == 1 ? (
                                        <td class="delete_gdgt"></td>
                                      ) : (
                                        <td class="delete_gdgt">
                                          <button
                                            class="delete-btn"
                                            value={gadget_list.id}
                                            onClick={text =>
                                              this.removeProduct(
                                                text.target.value,
                                                key
                                              )
                                            }
                                          >
                                            <i
                                              class="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                            Delete
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }.bind(this)
                              )}
                            </tbody>
                          </table>
                          <p>From the information provided by you during our online journey it has been established that you have a requirement for a Gadget insurance Policy to protect your

                          {gadget_list.map(
                            function(gadget_list, key) {
                                  return (
                                    <span> {(key) ? ',' : ''} {gadget_list.title}  </span>
                                  );
                            }.bind(this)
                          )}
                          .</p>
                        </form>
                      </div>
                      <div class="cus-details qu-left">
                        <div className="details_block">
                          <h4>Review</h4>
                          <div className="sub_block">
                            <h5>Full Name</h5>
                            <span>{this.state.fname}</span>  <span>{this.state.lname}</span>
                          </div>
                          <div className="sub_block">
                            <h5>Postcode</h5>
                            <span>{this.state.post}</span>
                          </div>
                          <div className="sub_block">
                            <h5>Address</h5>
                            <span>{this.state.address}</span>
                          </div>
                          <div className="sub_block">
                            <h5>Phone Number</h5>
                            <span>{this.state.tele}</span>
                          </div>
                          <div className="sub_block">
                            <h5>Email</h5>
                            <span>{this.state.email}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="notes">
                              <h6>Applicable excess:</h6>
                              <p>
                              An excess fee for any claim, which depends on the gadget’s value when New or Refurbished:
                              </p>
                              <ul className="list">
                                <li>Up to the value of £250 the excess is £25 claim.</li>
                                <li>Between the £251 and £999 the excess is £50.</li>
                                <li>Over £999 the excess fee is £75.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-3 mt-1">
                      <h6 class="text-center plan">
                        <strong style={styles.plans}> The premium for the policy </strong>
                      </h6>
                      
                      <div class="text-center">
                      
                                    <input
                          type="radio"
                          class="c_radio"
                          name="amount"
                          value={this.state.pricevalue}
                          id="b"
                          onClick={this.amount}
                        />
                                 
                        
                        <label
                          class="usl-payment y-payment text-center mt-3 bordr"
                          for="b"
                        >
                          <svg class="checkmark active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                          <h3>Annual Payment</h3>
                          <h2>£{this.state.planprice} </h2>
                          {/* <h4>12 Month for the price of 11</h4> */}
                          <p>
                            (Inclusive of all taxes)
                          </p>
                          <p>
                            
                          </p>
                        </label>
                      </div>
                    </div>
                    
                    <div class="col-12 text-center noprint">
                      <center class="my-2">
                        <a href="/quotes/Products" class="payment-back">
                          Back
                        </a>
                      </center>
                      <button
                        type="button"
                        class="mt-5 w-100 payment-btn"
                        onClick={this.addPriceDetails}
                      >
                        Make Payment
                      </button>&nbsp;&nbsp;
                      {/* <button
                        className="mt-5 nxt_btn noprint printbtn"
                        type="button"
                        onClick={() => this.print()}
                        value="print"
                        >Print
                        </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Plan;
