import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import Sidebanner from '../layout/Sidebanner';
import InputMask from "react-input-mask";
import MetaTags from 'react-meta-tags';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import Iframe from 'react-iframe';

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentStart: '',
            card_holder: '',
            card_number: '',
            card_expiry: '',
            document: "",
            tab: 1,
            garentee: 0,
            cvv: '',
            account_holder: '',
            account_number: '',
            sort_code: '',
            payment_date: "",
            paymentDetails: [],
            quote_id: '',
            order_id: '',
            amount: '',
            reference_no: '',
            message: '',
            trans_id: '',
            status_code: '',
            payment_type: '',
            auth_code: '',
            paymentDate: [],
            checked1: '',
            cehck: '',
            card_holder1: '',
            getPaymentDate: [],
            err_msg: '',
            modalOpen: false,
            modalOpen1: false,
        }
        this.handleChange = this
            .handleChange
            .bind(this);
        this.showContent = this
            .showContent
            .bind(this);
        this.showContent1 = this
            .showContent1
            .bind(this);
        this.payment = this
            .payment
            .bind(this);
    }
    state = {
        checked: false
    }
    handleOpen = () => {
        this.setState({ modalOpen: true });
    }
    handleOpen1 = () => {
        this.setState({ modalOpen1: true });
    }
    handleClose1 = event => {

        this.setState({ modalOpen1: false });
    }
    handleClose = event => {

        this.setState({ modalOpen: false });
    }

    handleCheckboxChange = event => {
        let checked = event.target.checked;
        if (checked == true) {

            this.setState({ checked: '1' });
        } else {
            this.setState({ checked: '' });
        }
    }
    changetab = (id) => {
        this.setState({ tab: id });
    }
    payment = (event) => {

        if (event.target.value == 'direct_debit') {
            this.setState({ direct_debit: '1' });
        }
        if (event.target.value == 'card_payment') {
            this.setState({ direct_debit: '2' });
        }
    }

    showContent = () => {
        localStorage.removeItem('payment_status');
        this.setState({ status: false });
    }
    showContent1 = () => {
        localStorage.removeItem('card_payment_status');
        this.setState({ status: false });
    }
    handleChange(event) {

        const state = this.state
        state[event.target.name] = event
            .target
            .value
        this
            .setState({ checked: event.target.checked })
        this.setState(state);

        this.setState({
            card_holder1: '',
            card_number1: '',
            card_expiry1: '',
            cvv1: '',
            account_holder1: '',
            account_number1: '',
            sort_code1: '',
            payment_date1: '',
            // password1 :''
        })
    }
    validateAddressPage() {
        var validatepage = true;
        var card_holder = this.state.card_holder;
        var card_number = this.state.card_number;
        var card_expiry = this.state.card_expiry;
        var cvv = this.state.cvv;
        var account_holder = this.state.account_holder;
        var account_number = this.state.account_number;
        var sort_code = this.state.sort_code;
        var payment_date = this.state.payment_date;
        var cehck = this.state.checked;
        // var password = this.state.password;

        if (this.state.direct_debit == '2') {

            if (card_holder == '' || card_holder == null || card_holder == undefined) {
                validatepage = false;

                this.setState({ card_holder1: 'alert' });
            } else {
                this.setState({ card_holder1: 'alert1' });
            }
            if (card_number == '' || card_number == null || card_number == undefined) {
                validatepage = false;

                this.setState({ card_number1: 'alert' });
            } else {
                this.setState({ card_number1: 'alert1' });
            }
            if (card_expiry == '' || card_expiry == null || card_expiry == undefined) {
                validatepage = false;

                this.setState({ card_expiry1: 'alert' });
            } else {
                this.setState({ card_expiry1: 'alert1' });
            }
            if (cehck == "" || cehck == null || cehck == undefined) {
                validatepage = false;

                this.setState({ checked1: 'not' });
            } else {
                this.setState({ checked1: 'not1' });
            }
            if (cvv == '' || cvv == null || cvv == undefined) {
                validatepage = false;

                this.setState({ cvv1: 'alert' });
            } else {

                this.setState({ cvv1: 'on' });
            }
        }

        if (this.state.direct_debit == '1') {

            if (account_holder == '' || account_holder == null || account_holder == undefined) {
                validatepage = false;

                this.setState({ account_holder1: 'alert' });
            } else {
                this.setState({ account_holder1: 'alert1' });
            }
            if (account_number == '' || account_number == null || account_number == undefined) {
                validatepage = false;

                this.setState({ account_number1: 'alert' });
            } else {
                this.setState({ account_number1: 'alert1' });
            }
            if (cehck == '' || cehck == null || cehck == undefined) {
                validatepage = false;

                this.setState({ checked1: 'not' });
            } else {
                this.setState({ checked1: 'not1' });
            }
            if (sort_code == '' || sort_code == null || sort_code == undefined) {
                validatepage = false;

                this.setState({ sort_code1: 'alert' });
            } else {
                this.setState({ sort_code1: 'alert1' });
            }
            if (payment_date == '' || payment_date == null || payment_date == undefined) {
                validatepage = false;

                this.setState({ payment_date1: 'alert' });
            } else {
                this.setState({ payment_date1: 'alert1' });
            }
        }
        return validatepage;

    }
    makePayment = () => {
        if (this.validateAddressPage()) {

            // var card_holder = this.state.card_holder;
            // var card_number = this.state.card_number;
            // var card_expiry = this.state.card_expiry;
            // var cvv = this.state.cvv;
            // var account_holder = this.state.account_holder;
            // var account_number = this.state.account_number;
            // var sort_code = this.state.sort_code;
            // var payment_date = this.state.payment_date;
            // var check = this.state.checked;
            // var password = this.state.password;

            if (this.state.direct_debit == '2' || this.state.direct_debit == '1') {
                var date = new Date().getDate(); //Current Date
                var month = new Date().getMonth() + 1; //Current Month
                var year = new Date().getFullYear();
                localStorage.setItem("card_holder", this.state.card_holder);
                localStorage.setItem("card_number", this.state.card_number);
                localStorage.setItem("card_expiry", this.state.card_expiry);
                localStorage.setItem("cvv", this.state.cvv);
                localStorage.setItem("account_holder", this.state.account_holder);
                localStorage.setItem("account_number", this.state.account_number);
                localStorage.setItem("sort_code", this.state.sort_code);
                localStorage.setItem("first_payment_date", this.state.payment_date);
                localStorage.setItem("password", this.state.password);
                localStorage.setItem("payment_date", (new Date().getDate()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getFullYear()));

                if (this.state.direct_debit == '2') {

                    var apiUrl = Config.crm + 'cmgpayment';

                    var cardexpiry = this.state.card_expiry;
                    var cardexpirysplit = cardexpiry.split("/");
                    localStorage.setItem("payment_method", 'card-payment');




                    const headers = new Headers();
                    headers.set('Accept', 'application/json');
                    // headers.set('Content-Type', 'multipart/form-data');
                    const data = {
                        AMOUNT: localStorage.getItem('amount'),
                        POSTCODE: localStorage.getItem('hacpost'),
                        OWNERADDRESS: localStorage.getItem('hacaddress'),
                        CN: this.state.card_holder,
                        CARDNO: this.state.card_number,
                        ED: this.state.card_expiry,
                        CVC: this.state.cvv,

                        // account_holder: this.state.account_holder,
                        // account_number: this.state.account_number,
                        // sort_code: this.state.sort_code,
                        // payment_date: this.state.payment_date,
                        // type: '',
                        REFERENCE_ID: localStorage.getItem('reference_no'),
                        ORDERID: localStorage.getItem('order_id'),
                        // password:password
                    }
                    const options = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers
                    };
                    fetch(apiUrl, options)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(
                            jsonStr => {
                                this.setState({ api_response: jsonStr.status });
                                // console.log(this.state.api_response);
                                if (this.state.api_response == 'success') {
                                    // localStorage.clear();
                                    window.location = "/quotes/Success";
                                }
                                else if (this.state.api_response == 'identification-requested') {
                                    const threeds = document.createElement("div");
                                    threeds.innerHTML = atob(jsonStr.redirection);
                                    document.body.appendChild(threeds);
                                    threeds.getElementsByTagName('form')[0].submit();
                                }
                                else {
                                    this.setState({ err_msg: this.state.api_response });
                                }
                            },
                            error => {
                                this.setState({ error });
                            }
                        );


                    // })
                } else {

                    this.setState({ err_msg: 'Unable to process payment .Please check your card details.' });
                }

                // })


            } else {
                var apiUrl = 'https://api-ddpayment.smart-cover.co.uk/api/lz/bankmodulus-and-sortcode-dd-enabled-check';
                // var apiUrl = 'http://adf7be7d0f1b.ngrok.io/api/lz/bankmodulus-and-sortcode-dd-enabled-check';
                const data = {
                    accountno: this.state.account_number,
                    sortcode: this.state.sort_code
                }
                localStorage.setItem("payment_method", 'dd-payment');
                const headers = new Headers();
                headers.set('Accept', 'application/json');
                headers.set('Content-Type', 'application/json');
                headers.set('Access-Control-Allow-Origin', '*');
                const options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers
                };
                fetch(apiUrl, options, { mode: 'no-cors' })
                    .then(function (response) {
                        console.log(response);
                        return response.json();
                    })
                    .then(jsonStr => {
                        if (jsonStr.status == '200') {

                            if (jsonStr.response_code = 'DetailsOk' && jsonStr.is_valid == 'true' && jsonStr.direct_debit_enabled == 'true') {
                                window.location = "/quotes/Quote_success";
                            } else {

                                this.setState({ err_msg: 'Please check your Account Number and Sort Code' });
                                console.log(this.state.err_msg);

                            }
                        } else {
                            this.setState({ err_msg: 'Authentication Failed.' });
                        }

                    }, (error) => {
                        this.setState({ error });

                    })
                // }
            }

        }
    }

    componentDidMount() {

        // var string = "Hello folks how are you doing today?";
        // var encodedString = btoa(string); 
        // var decodedString = atob(encodedString);
        // alert(decodedString);
        if (localStorage.getItem('glength') <= 0) {
            window.location = "/";
        }

        //payment date
        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');
        const url = Config.url + 'api/getDate';
        fetch(url, {
            method: 'GET',
            headers
        })
            .then(res => res.json())
            .then(result => {
                this.setState({ getPaymentDate: result })
            }, (error) => {
                this.setState({ error });
            })
        //end
        const url1 = Config.url + 'api/document';
        fetch(url1, {
            method: 'GET',
            headers
        })
            .then(function (response) {
                return response.json();
            })
            .then(jsonStr => {
                this.setState({ document: jsonStr });
                if (this.state.document == null) {
                    this.state.document = null;
                }
            });
    }
    showgarenteedata = () => {
        if (this.state.garentee == 0) {
            this.setState({ garentee: 1 });
        } else {
            this.setState({ garentee: 0 });
        }
    };
    componentWillMount() {

        if (localStorage.getItem('glength') <= 0 && localStorage.getItem('quote_id') == null) {
            window.location = "/";
        }
    }
    render() {
        const styles = {
            payment_failure: {
                height: '200px'
            }
        };
        return (
            <div>
                <Header />
                <MetaTags>
                    <title>Gadgets Quote</title>
                    <meta name="description" content="Some description." />
                    <meta property="og:title" content="MyApp" />
                    <meta property="og:image" content="path/to/image.jpg" />
                </MetaTags>
                <section id="pg-produc">
                    <div className="container">
                        <div class="row">
                            {/* <div class="col-md-4 col-lg-4">
                            <Sidebanner/>
                        </div> */}
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="gen-your-quote">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <h3>Generate your Quote</h3>
                                            <p>With just a few simple steps you will receive a personalised quote</p>
                                        </div>
                                    </div>
                                    <div class="pg-details-step">
                                        <div class="row justify-content-lg-around">
                                            <div class="col-md-12 col-lg-2 pd-steps active">
                                                <span class="prvstep">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </span>
                                                Your Details
                                                <i class="fas fa-angle-right"></i>
                                            </div>
                                            <div class="col-md-12 col-lg-2 pd-steps active">
                                                <span class="prvstep">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </span>
                                                Product Details
                                                <i class="fas fa-angle-right"></i>
                                            </div>
                                            <div class="col-md-12 col-lg-2 pd-steps active mbactive">
                                                <span class="prvstep">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </span>
                                                Product Review
                                                <i class="fas fa-angle-right"></i>
                                            </div>
                                            <div class="col-md-12 col-lg-2 pd-steps">
                                                <span class="activespan">4</span>
                                                Payment Methods
                                            </div>
                                            <div class="col-md-12 col-lg-2 pd-steps active">
                                                <span >5</span> Policy Summary
                                            </div>
                                        </div>
                                    </div>

                                    <div class="payment-method">

                                        <div class="row">
                                            {/* <div class="col-md-12">
                                            <h5 class="text-bold py-3">
                                            </h5>
                                        </div> */}
                                            {
                                                this.state.tab == 1 && <div class="col-sm-6 col-lg-4" onClick={() => this.changetab(1)}>
                                                    <button
                                                        class="p-option text-center p-5 w-100 border-0 bolder sl_pay font-weight-bold"
                                                        id="cpayment"
                                                        name="payment_type"
                                                        value="card_payment"
                                                        onClick={this.payment}>
                                                        <div class="sl_icon d-block" id="cp_ico">
                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        <span><img src="../images/cp.png" /></span>
                                                        &nbsp; &nbsp; Card Payment
                                                    </button>
                                                </div>
                                            }
                                            {
                                                this.state.tab == 2 && <div class="col-sm-6 col-lg-4" onClick={() => this.changetab(1)}>
                                                    <button
                                                        class="p-option text-center p-5 w-100 border-0 bolder  font-weight-bold"
                                                        id="cpayment"
                                                        name="payment_type"
                                                        value="card_payment"
                                                        onClick={this.payment}>
                                                        <div class="sl_icon" id="cp_ico">
                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        <span><img src="../images/cp.png" /></span>
                                                        &nbsp; &nbsp; Card Payment
                                                    </button>
                                                </div>
                                            }

                                            {
                                                this.state.tab == 1 && <div class="col-sm-6 col-lg-4" onClick={() => this.changetab(2)}>

                                                    <button
                                                        class="p-option text-center p-5 w-100 border-0 bolder font-weight-bold"
                                                        id="ddebit"
                                                        name="payment_type1"
                                                        value="direct_debit"
                                                        onClick={this.payment}>
                                                        <div class="sl_icon" id="dd_ico">
                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        <span><img src="../images/dd.png" /></span>
                                                        &nbsp; &nbsp; Direct Debit
                                                    </button>
                                                </div>
                                            }
                                            {
                                                this.state.tab == 2 && <div class="col-sm-6 col-lg-4" onClick={() => this.changetab(2)}>
                                                    <button
                                                        class="p-option text-center p-5 w-100 border-0 bolder sl_pay font-weight-bold"
                                                        id="ddebit"
                                                        name="payment_type1"
                                                        value="direct_debit"
                                                        onClick={this.payment}>
                                                        <div class="sl_icon d-block" id="dd_ico">
                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        <span><img src="../images/dd.png" /></span>
                                                        &nbsp; &nbsp; Direct Debit
                                                    </button>
                                                </div>
                                            }

                                        </div>

                                        {
                                            this.state.tab == 1 && <form class="cd-form d-block" action="/complete" id='cmg_payment_form'>
                                                <h4>Enter your card details</h4>
                                                {this.state.err_msg == '' ? '' : <span style={{ color: 'red' }}>{this.state.err_msg}</span>}
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <TextField label="Card Holder"
                                                                value={this.state.card_holder}
                                                                name="card_holder"
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                                variant="outlined" />
                                                            {this.state.card_holder1 == "alert"
                                                                ? <div class="srv-validation-message">The card holder field is required.</div>
                                                                : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <TextField label="Card Number"
                                                                value={this.state.card_number}
                                                                name="card_number"
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                                variant="outlined" />
                                                            {this.state.card_number1 == "alert"
                                                                ? <div class="srv-validation-message">The card number field is required.</div>
                                                                : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <InputMask
                                                                mask="99/99"
                                                                value={this.state.card_expiry}
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                            >
                                                                <TextField
                                                                    label="Card Expiry"
                                                                    name="card_expiry"
                                                                    className="form_control"
                                                                    margin="normal"
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </InputMask>
                                                            {
                                                                this.state.card_expiry1 == "alert"
                                                                    ? <div class="srv-validation-message">The card expiry field is required.</div>
                                                                    : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <InputMask
                                                                value={this.state.cvv}
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                            >
                                                                <TextField
                                                                    label="CVV"
                                                                    name="cvv"
                                                                    className="form_control"
                                                                    margin="normal"
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </InputMask>
                                                            {
                                                                this.state.cvv1 == "alert"
                                                                    ? <div class="srv-validation-message">The card cvv field is required.</div>
                                                                    : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                        {
                                            this.state.tab == 2 && <form class="dd-form d-block">
                                                <h4>Enter your card details</h4>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <TextField label="Account Holder"
                                                                value={this.state.account_holder}
                                                                name="account_holder"
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                                variant="outlined" />
                                                            {this.state.account_holder1 == "alert"
                                                                ? <div class="srv-validation-message">The account holder field is required.</div>
                                                                : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <TextField label="Account Number"
                                                                value={this.state.account_number}
                                                                name="account_number"
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                                variant="outlined" />
                                                            {this.state.account_number1 == "alert"
                                                                ? <div class="srv-validation-message">The account number field is required.</div>
                                                                : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <TextField label="Sort Code"
                                                                value={this.state.sort_code}
                                                                name="sort_code"
                                                                onChange={this.handleChange}
                                                                className="form_control"
                                                                variant="outlined" />
                                                            {this.state.sort_code1 == "alert"
                                                                ? <div class="srv-validation-message">The sort code field is required.</div>
                                                                : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="Form-group">
                                                            <FormControl variant="outlined" className="form_control">
                                                                <InputLabel htmlFor="outlined-new-refurb-native-simple">First payment date</InputLabel>
                                                                <Select
                                                                    native
                                                                    value={this.state.payment_date}
                                                                    name="payment_date"
                                                                    onChange={this.handleChange}
                                                                    label="First payment date"
                                                                >
                                                                    <option value="" disabled="disabled" selected="selected"></option>
                                                                    {
                                                                        Object
                                                                            .keys(this.state.getPaymentDate)
                                                                            .map(function (item, key) {
                                                                                return (<option value={item}>{moment(item).format('DD-MM-YYYY')}</option>)
                                                                            }.bind(this))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            {
                                                                this.state.payment_date1 == "alert"
                                                                    ? <div class="srv-validation-message">The Payment date field is required.</div>
                                                                    : <div></div>
                                                            }
                                                        </div>
                                                        {/* <div class="wrap">
                                                            <div class="select">
                                                                <select
                                                                    className="select-text"
                                                                    name="payment_date"
                                                                    value={this.state.payment_date}
                                                                    onChange={this.handleChange}
                                                                    required="required">
                                                                    <option value="" disabled="disabled" selected="selected"></option>
                                                                    {
                                                                        Object
                                                                            .keys(this.state.getPaymentDate)
                                                                            .map(function (item, key) {
                                                                                return (<option value={item}>{item}</option>)
                                                                            }.bind(this))
                                                                    }
                                                                </select>
                                                                <label class="select-label">First payment date</label>
                                                            </div>
                                                            {
                                                                this.state.payment_date1 == "alert"
                                                                    ? <div class="srv-validation-message">The Payment date field is required.</div>
                                                                    : <div></div>
                                                            }
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {this.state.err_msg == '' ? '' : <span style={{ color: 'red' }}>{this.state.err_msg}</span>}
                                            </form>
                                        }
                                        {
                                            localStorage.getItem('payment_status') == '1'
                                                ? <div class="sucess-sec failure text-center" style={styles.payment_failure}>
                                                    {this.state.position}
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <i class="fa fa-close dainger-ico mt-5" aria-hidden="true"></i>
                                                            <h2 class="pt-3">failed #invalid account number or sort code</h2>
                                                            <h4>
                                                                Please Try Again
                                                            </h4>

                                                        </div>
                                                    </div>
                                                </div>
                                                : <div></div>
                                        }
                                        {
                                            localStorage.getItem('card_payment_status') == '0'
                                                ? <div class="sucess-sec failure text-center" style={styles.payment_failure}>
                                                    {this.state.position}
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <i class="fa fa-close dainger-ico mt-5" aria-hidden="true"></i>
                                                            <h2 class="pt-3">
                                                                Payment Declined
                                                            </h2>
                                                            <h4>
                                                                Please Try Again
                                                            </h4>

                                                        </div>
                                                    </div>
                                                </div>
                                                : <div></div>
                                        }

                                        {this.state.tab == 2 && (


                                            this.state.garentee == "0" ? (
                                                <div>
                                                    <button
                                                        class="btn w-100  waves-effect waves-light"
                                                        type="button"
                                                        onClick={this.showgarenteedata}
                                                        data-toggle="collapse"
                                                        data-target="#collapseExample"
                                                        aria-expanded="false"
                                                        aria-controls="collapseExample"
                                                        value={this.state.garentee}
                                                    >
                                                        The Direct Debit Guarantee
                                                        <div class="acc-ico">
                                                            <img src="../images/arrow-.png" />
                                                        </div>
                                                    </button>

                                                </div>
                                            ) : (
                                                <div>
                                                    <button
                                                        class="btn w-100  waves-effect waves-light"
                                                        type="button"
                                                        onClick={this.showgarenteedata}
                                                        data-toggle="collapse"
                                                        data-target="#collapseExample"
                                                        aria-expanded="false"
                                                        aria-controls="collapseExample"
                                                        value={this.state.garentee}
                                                    >
                                                        The Direct Debit Guarantee
                                                        <div class="acc-ico2">
                                                            <img src="../images/arrow-.png" />
                                                        </div>
                                                    </button>
                                                    <div class='' id="collapseExample">
                                                        <div class="card card-body">
                                                            <p>This guarantee is offered by all banks and buildings societies that accept instructions to pay Direct Debits.</p>
                                                            <p>If there are any changes to the amount, date or frequency of your Direct Debit Smart-Cover Direct Limited will notify you 10 working days in advance of your account being debited or as otherwise agreed. If you request Smart-Cover Direct Limited to collect a payment, confirmation of the amount and date will be given to you at the time of the request.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            // </div>
                                        )}

                                        <div class="terms">
                                            <h5 class="mt-5">Terms & Conditions</h5>
                                            <p>Please make sure that you have downloaded and read all of the following Terms
                                                & Conditions documentation.
                                            </p>
                                            {/* <p>Please note that some of our policies are underwritten by companies outside
                                            of the European Economic Area.</p> */}
                                            <p>Financial Services Compensation Scheme may not apply
                                                with respect to policy performance. Please pay special attention to the
                                                complaints section of the terms and conditions for more information.
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <input
                                                type="checkbox"
                                                name="term_con"
                                                value="check"
                                                onChange={this.handleCheckboxChange}
                                                autocomplete="off" />
                                            &nbsp;&nbsp;&nbsp;I have read the Terms & Conditions and IPID Document

                                        </div>

                                        <label
                                            style={{
                                                display: this.state.checked1 == 'not'
                                                    ? 'block'
                                                    : 'none',
                                                color: 'red'
                                            }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp; please accept the terms and conditions
                                        </label>
                                        <br />

                                        <Modal
                                            open={this.state.modalOpen}
                                            onClose={this.handleClose}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            style={{ background: 'rgba(0,0,0,.8)', padding: '30px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <React.Fragment >
                                                <Iframe width="80%" height="100%" src={Config.siteurl + "/wp-content/uploads/2021/06/SC-Gadget-29_pw.pdf"} />
                                                <button type="button" className="close_ico" onClick={this.handleClose}></button>
                                            </React.Fragment>
                                        </Modal>
                                        <Modal
                                            open={this.state.modalOpen1}
                                            onClose={this.handleClose1}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            style={{ background: 'rgba(0,0,0,.8)', padding: '30px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <React.Fragment >
                                                <Iframe width="80%" height="100%" src={Config.siteurl + "/wp-content/uploads/2021/06/SC-IPID-29_kf_.pdf"} />
                                                <button type="button" className="close_ico" onClick={this.handleClose1}></button>
                                            </React.Fragment>
                                        </Modal>
                                        <div class="documents">
                                            <a className="mr-4"
                                                onClick={() => this.handleOpen()}
                                            >
                                                <span><img src="../images/pdf.png" /></span>Terms & Conditions</a>
                                            <a
                                                onClick={() => this.handleOpen1()}
                                            >
                                                <span><img src="../images/ipid.png" /></span>IPID</a>
                                        </div>
                                        <div class="text-right">

                                            <button class="mt-5 payment-btn w-30" onClick={this.makePayment}>Make Payment</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}
export default Payment;